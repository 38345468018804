import localeAntd from 'antd/es/locale/en_US'

import idID from './id-ID.js'

const messages = {
  ...idID.messages,
  'actions.all': 'All',
  'actions.cancel': 'Cancel',
  'actions.close': 'Close',
  'actions.delete': 'Delete',
  'actions.delete.confirm': 'Are you sure?',
  'actions.detail': 'Detail',
  'actions.download': 'Download',
  'actions.downloadSample': 'Download Template',
  'actions.duplicate': 'Duplicate',
  'actions.export': 'Export',
  'actions.export.doc': 'Download DOC',
  'actions.export.pdf': 'Download PDF',
  'actions.export.xls': 'Download XLS',
  'actions.fillOut': 'Fill Out',
  'actions.goBack': 'Go Back',
  'actions.goHome': 'Go to Home',
  'actions.ok': 'OK',
  'actions.pickOne': '-- Select --',
  'actions.preview': 'Preview',
  'actions.reset': 'Reset',
  'actions.save': 'Save',
  'actions.search': 'Search',
  'actions.send': 'Send',
  'actions.start': 'Start',
  'actions.stop': 'Stop',
  'actions.update': 'Update',
  'actions.upload': 'Upload',
  'actions.validate': 'Validate',
  'errors.tryAgain': 'Try Again',
  'errors.formValidation.invalidEmail': 'Email address is not valid',
  'errors.formValidation.required': 'This input field is required',
  'errors.timeout':
        'Something goes wrong with the request. Check your internet connection and try again later.',
  'errors.404': 'Page not found',
  'hints.file': 'Click or drag file to this area to upload',
  'm.users': 'User',
  'm.users.login': 'Login',
  'm.users.logout': 'Logout',
  'm.users.fields.nama': 'Name',
  'm.users.fields.username': 'Username',
  'menu.group': 'Group',
  'menu.item': 'Item',
  'table.fields._i': 'No',
  'table.fields._actions': 'Actions',
  'table.pagination.total':
        'Showing {start, number} to {end, number} of {total, number} {total, plural, one {entry} other {entries}}',
  'success.ok': 'OK'
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  locale: 'en-US',
  localeAntd,
  messages
}
