import { Card } from 'antd'

export default function Widget ({ children, ...rest }) {
  if (!rest.className) rest.className = ''
  rest.className = `ma-widget ${rest.className}`
  return (
    <Card {...rest}>
      {children}
    </Card>
  )
}
