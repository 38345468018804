import { Collapse } from 'antd'

export default function Hint3 () {
  return (
    <Collapse>
      <Collapse.Panel header='Skoring dari level 1.00 hingga 10.00 dengan gambaran:'>
        <table>
          <tbody>
            <tr>
              <th scope='row'>1</th>
              <td>
                Jika efek pada Loss of Load Probability (LOLP)
                sangat kecil sekali (Pembangkit)
              </td>
            </tr>
            <tr>
              <th scope='row'>5</th>
              <td>
                N-2 contigency untuk Transmisi EHV yang
                menyalurkan pembangkit sangat besar (mis.
                Paiton, Suralaya)<br />
                Ring loop untuk Distribusi<br />
                Jika efek pada Loss of Load Probability (LOLP)
                sedang (Pembangkit)
              </td>
            </tr>
            <tr>
              <th scope='row'>8</th>
              <td>N-1 contigency (Transmisi HV)</td>
            </tr>
            <tr>
              <th scope='row'>10</th>
              <td>
                N-1 contigency (Transmisi EHV)<br />
                Jika efek pada Loss of Load Probability (LOLP) sangat tinggi (Pembangkit)
              </td>
            </tr>
          </tbody>
        </table>
      </Collapse.Panel>
    </Collapse>
  )
}
