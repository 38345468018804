import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Badge, Table, Tooltip } from 'antd'
import { CheckCircleOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'

import { useLocalization } from '../../../localization'
import { getProjects } from '../../../services'
import { useMountEffect, usePagination, FilterText } from '../../'

export default function ProjectsTable ({ isApproval = false }) {
  const [data, setData] = useState([])
  const [pagination, setPagination] = usePagination()
  const [loading, setLoading] = useState(false)
  const { intl, t } = useLocalization()

  const fetchProjects = async (params = {}) => {
    setLoading(true)
    try {
      if (isApproval) params.is_approved = false
      const { data, meta: { item, page } } = await getProjects(params)
      setData(data.map((item, i) => Object.assign({ _i: (page.current - 1) * page.perPage + i + 1 }, item)))
      setPagination(
        Object.assign({}, pagination, {
          pageSize: params.perPage || page.perPage,
          total: item.total
        })
      )
    } catch (error) { }
    setLoading(false)
  }

  const handleTableChange = ({ current, pageSize }, filters, sorter) => {
    setPagination(Object.assign({}, pagination, { current }))
    fetchProjects({
      perPage: pageSize,
      page: current,
      sortField: sorter.field,
      sortOrder: sorter.order === 'descend' ? 'desc' : 'asc',
      ...filters
    })
  }

  useMountEffect(() => {
    fetchProjects()
  })

  const columns = [
    { title: t('table.fields._i'), dataIndex: '_i', width: 40 },
    {
      title: t('m.projects.fields.name.short'),
      dataIndex: 'name',
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, visible }) => (
        <FilterText setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys} confirm={confirm} clearFilters={clearFilters} visible={visible} />
      )
    },
    {
      title: t('m.projects.fields.unit.short'),
      dataIndex: 'unit',
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, visible }) => (
        <FilterText setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys} confirm={confirm} clearFilters={clearFilters} visible={visible} />
      )
    },
    {
      title: t('m.projects.fields.startingDate'),
      dataIndex: 'starting_date',
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, visible }) => (
        <FilterText setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys} confirm={confirm} clearFilters={clearFilters} visible={visible} />
      ),
      render: text => intl.formatDate(text)
    },
    {
      title: t('m.projects.fields.valuation'),
      dataIndex: 'valuation',
      align: 'right',
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, visible }) => (
        <FilterText setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys} confirm={confirm} clearFilters={clearFilters} visible={visible} />
      ),
      render: text => intl.formatRupiah(text)
    },
    {
      title: t('m.projects.fields.review.score'),
      dataIndex: 'review_score',
      sorter: true,
      filters: [
        { text: t('m.projects.fields.mandatory.short'), value: 'is_mandatory' },
        { text: t('m.projects.fields.profitable.short'), value: 'is_profitable' }
      ],
      render: (text, row) => {
        const badge = row.approved_at
          ? (
            <Tooltip title={t('m.projects.fields.review.approved')}>
              <Badge count={<CheckCircleOutlined style={{ color: '#52c41a', marginLeft: 5 }} />} />
            </Tooltip>
            )
          : (
              ''
            )
        let finalText = t('m.projects.fields.review.nonPriority')
        if (row.is_mandatory) finalText = t('m.projects.fields.mandatory.short')
        else if (row.is_profitable) finalText = t('m.projects.fields.profitable.short')
        return (
          <>
            {finalText}
            {badge}
          </>
        )
      }
    },
    {
      title: t('table.fields._actions'),
      dataIndex: 'id',
      className: 'text-center',
      width: isApproval ? 80 : 120,
      render: (data, row) => (
        <div className='actions-btn'>
          <Tooltip title={t('actions.detail')}>
            <Link className='ant-btn ant-btn-sm ant-btn-primary' to={`/projects/${data}/detail`} state={{ isApproval }}>
              <EyeOutlined />
            </Link>
          </Tooltip>
          {row.approved_at === null && !isApproval && (
            <Tooltip title={t('actions.update')}>
              <Link className='ant-btn ant-btn-sm ant-btn-warning' to={`/projects/${data}/update`}>
                <EditOutlined />
              </Link>
            </Tooltip>
          )}
        </div>
      )
    }
  ]

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={loading}
      onChange={handleTableChange}
      pagination={pagination}
      rowKey={record => record.id}
    />
  )
}
