import { useState } from 'react'

import moment from 'moment'

import { useMountEffect } from '../components'
import { ApiUtils } from '../utils'

import { normalizeErrors } from '.'

export const getProjects = async params => {
  const cleanedParams = ApiUtils.cleanParams(params)
  const queryParams = new URLSearchParams(cleanedParams)
  return ApiUtils.request({ url: `projects?${queryParams.toString()}`, method: 'GET' })
}

export const postProject = async project => {
  project.documents = project.documents?.map(doc => doc.response?.data.id || doc.id)
  return ApiUtils.request({ url: 'projects', body: project })
}

export const getProjectStatistic = async () => ApiUtils.request({ url: 'projects/statistic' })

export const getProject = async id => ApiUtils.request({ url: `projects/${id}` })

export function useFetchProject (id, onError) {
  const [project, setProject] = useState({ step: 0, wacc: 12 })
  const [loading, setLoading] = useState(false)

  const fetchProject = async () => {
    setLoading(true)
    try {
      const { data } = await getProject(id)
      data.step = 0
      data.starting_date = moment(data.starting_date)
      data.mandatory_params = [0, 1, 2, 3, 4].filter(i => data[`mandatory_${i}`])
      data.params = [0, 1, 2, 3, 4, 5, 6].filter(i => data[`param_${i}`])
      data.is_approved = Boolean(data.approved_at)
      setProject(data)
    } catch (error) {
      if (onError) onError(normalizeErrors(error.message))
    }
    setLoading(false)
  }

  useMountEffect(() => {
    fetchProject()
  })

  return [project, loading, setProject]
}

export const putProject = async project => {
  project.documents = project.documents?.map(doc => doc.response?.data.id || doc.id)
  return ApiUtils.request({ url: `projects/${project.id}`, method: 'PUT', body: project })
}

export const putProjectApproval = async project => ApiUtils.request({ url: `projects/${project.id}/approval`, method: 'PUT', body: project })
