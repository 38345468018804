import { createContext, useContext, useEffect, useState } from 'react'

import { version } from '../../package.json'

export const KEY_AUTH = 'investAuthUser'

const authContext = createContext()

export function ProvideAuth ({ children }) {
  const auth = useProvideAuth()
  const { signOut, user } = auth

  useEffect(() => {
    if (user && user.appVersion !== version) {
      setTimeout(() => {
        signOut()
      }, 200)
    }
  // eslint-disable-next-line
  }, [user])

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

function useProvideAuth () {
  const [user, setUser] = useState(null)

  const reload = () => {
    try {
      const user = JSON.parse(localStorage.getItem(KEY_AUTH))
      setUser(user)
    } catch (error) { }
  }

  const signIn = user => {
    user.appVersion = version
    localStorage.setItem(KEY_AUTH, JSON.stringify(user))
    setUser(user)
  }

  const signOut = () => {
    localStorage.removeItem(KEY_AUTH)
    setUser(null)
  }

  return {
    user,
    reload,
    signIn,
    signOut
  }
}
