export * from './project'
export * from './session'

export const normalizeErrors = (errorMsg, t) => {
  if (!errorMsg) return
  try {
    const error = JSON.parse(errorMsg)
    if (t && error.l10n) return t(error.l10n)
    if (typeof error === 'object') {
      let messages = []
      for (const key in error) {
        if (typeof error[key] === 'string') messages.push(error[key])
        else if (Array.isArray(error[key])) messages = messages.concat(error[key])
      }
      return messages
    }
    return error
  } catch (error) {
    return errorMsg
  }
}
