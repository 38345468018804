import { Layout } from 'antd'

import { version } from '../../../package.json'
import { useAuth } from '../../contexts'
import { useLocalization } from '../../localization'

import Sidebar from './sidebar'

const { Content, Footer, Header } = Layout

const currentYear = new Date().getFullYear()

const withMainLayout = (WrappedComponent, title) => props => {
  const { user } = useAuth()
  const { t } = useLocalization()

  document.title = `${title ? `${t(title)} | ` : ''}${t('app.name')}`

  return (
    <Layout className='ma-layout'>
      <Sidebar />
      <Layout>
        <Header>
          <span className='ma-xs-only'>{t('app.name')}</span>
          <span className='ma-xs-hide'>{t('app.fullname')}</span>
          <span className='ma-xs-hide'>{t('m.users.loginAs')}: {user.name}</span>
        </Header>
        <Content>
          <WrappedComponent {...props} />
        </Content>
        <Footer>
          <div>
            <span className='ma-xs-hide'>
              Copyright {t('app.owner')}
            </span>{' '}
            © {2021}
            {currentYear === 2021 ? '' : ` - ${currentYear}`}
          </div>
          <div>v {version}</div>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default withMainLayout
