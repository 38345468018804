import { useState } from 'react'
import { Col, Row } from 'antd'

import { withMainLayout, Widget } from '../../components'
import {
  ProjectCategoryForm,
  ProjectIdentifyForm,
  ProjectIRRCheckForm,
  ProjectMandatoryCheckForm,
  ProjectNonInfraParameterForm,
  ProjectParameterForm,
  ProjectParameterSelectionForm,
  ProjectSummaryForm
} from '../../components/modules'
import { useLocalization } from '../../localization'

const formMapper = [
  ProjectIdentifyForm,
  ProjectMandatoryCheckForm,
  ProjectIRRCheckForm,
  ProjectCategoryForm,
  ProjectParameterSelectionForm,
  ProjectParameterForm,
  ProjectNonInfraParameterForm
]

function CreationPage () {
  const [project, setProject] = useState({ step: 0, wacc: 12 })
  const { t } = useLocalization()

  const { step } = project
  const FormComponent = formMapper[step] || ProjectIdentifyForm

  return (
    <div>
      <h2 className='title'>{t('m.projects.create')}</h2>
      <Row gutter={24}>
        {step < 100 && (
          <Col span={step > 0 ? 16 : 24}>
            <Widget>
              <FormComponent value={project} onChange={setProject} />
            </Widget>
          </Col>
        )}
        {(step > 0 || step === 100) && (
          <Col span={step === 100 ? 24 : 8}>
            <Widget title={t('m.projects.summary')}>
              <ProjectSummaryForm
                value={project}
                onChange={setProject}
              />
            </Widget>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default withMainLayout(CreationPage, 'm.projects.create')
