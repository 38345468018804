import { useState } from 'react'
import { Button, Divider, Form, Select } from 'antd'

import { useLocalization } from '../../../../localization'

export default function ProjectCategoryForm ({ value, onChange }) {
  const [form] = Form.useForm()
  const { t } = useLocalization()
  const [loading, setLoading] = useState(false)

  const handleBack = () => {
    const step = value.step - 1
    onChange(Object.assign({}, value, { step }))
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const newValue = await form.validateFields()
      const step = newValue.category === 4 ? 6 : 4
      const additionalValue = { step, prevStep: 3 }
      if (newValue.category !== value.category) {
        additionalValue.params = []
        additionalValue.score = 0
        for (let i = 0; i < 7; i++) {
          additionalValue[`param_${i}`] = 0
        }
        for (let i = 0; i < 4; i++) {
          additionalValue[`param_4${i}`] = 0
        }
      }
      onChange(
        Object.assign({ }, value, newValue, additionalValue)
      )
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Form form={form} layout='vertical' initialValues={value}>
      <Form.Item
        label={t('m.projects.fields.category')}
        name='category'
        rules={[
          {
            required: true,
            message: t('errors.formValidation.required')
          }
        ]}
      >
        <Select placeholder={t('actions.pickOne')}>
          {[0, 1, 2, 3, 4].map(i => <Select.Option key={i} value={i}>{t(`m.projects.fields.category.${i}`)}</Select.Option>)}
        </Select>
      </Form.Item>
      <Divider />
      <Form.Item>
        <Button onClick={handleBack} loading={loading} style={{ marginRight: 10 }}>
          {t('actions.prev')}
        </Button>
        <Button type='primary' onClick={handleSubmit} loading={loading}>
          {t('actions.next')}
        </Button>
      </Form.Item>
    </Form>
  )
}
