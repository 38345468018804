import { useState } from 'react'
import {
  message,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Upload
} from 'antd'
import { InboxOutlined } from '@ant-design/icons'

import { useAuth } from '../../../../contexts'
import { useLocalization } from '../../../../localization'
import { ApiUtils } from '../../../../utils'

export default function ProjectIdentifyForm ({ value, onChange }) {
  const [form] = Form.useForm()
  const { user } = useAuth()
  const { intl, t } = useLocalization()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const newValue = await form.validateFields()
      newValue.documents?.forEach(doc => {
        if (doc.percent > 0 && doc.percent < 100) {
          message.error(t('errors.formValidation.uploading'))
          throw new Error()
        }
      })
      newValue.documents = newValue.documents?.filter(doc => doc.percent === 100 || doc.id)
      onChange(Object.assign({}, value, newValue, { step: 1, prevStep: 0 }))
    } catch (error) {
      setLoading(false)
    }
  }

  const normFile = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  return (
    <Form form={form} layout='vertical' initialValues={value}>
      <Form.Item
        label={t('m.projects.fields.name')}
        name='name'
        rules={[
          {
            required: true,
            message: t('errors.formValidation.required')
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('m.projects.fields.unit')}
        name='unit'
        rules={[
          {
            required: true,
            message: t('errors.formValidation.required')
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('m.projects.fields.startingDate')}
        name='starting_date'
        rules={[
          {
            required: true,
            message: t('errors.formValidation.required')
          }
        ]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label={t('m.projects.fields.valuation')}
        name='valuation'
        rules={[
          {
            required: true,
            message: t('errors.formValidation.required')
          }
        ]}
      >
        <InputNumber
          formatter={intl.formatRupiah}
          parser={value => value.replace(/Rp\s?|(\.*)/g, '')}
          min={0}
          step={100000000}
          style={{ width: 155 }}
        />
      </Form.Item>
      <Form.Item
        label={t('m.projects.fields.description')}
        name='description'
        rules={[
          {
            required: true,
            message: t('errors.formValidation.required')
          }
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item label={t('m.projects.fields.documents')}>
        <Form.Item
          name='documents'
          valuePropName='fileList'
          getValueFromEvent={normFile}
          noStyle
        >
          <Upload.Dragger
            name='document'
            action={ApiUtils.serverUrl(`documents/${user.id}`)}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>{t('hints.file')}</p>
            <p className='ant-upload-hint'>
              {t('hints.file.multiple')}
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>
      <Divider />
      <Form.Item>
        <Button type='primary' onClick={handleSubmit} loading={loading}>
          {t('actions.next')}
        </Button>
      </Form.Item>
    </Form>
  )
}
