import { Route, Routes } from 'react-router-dom'

import Error404 from '../error/404'
import PrivateRoute from '../privateRoute'

import ApprovalPage from './approval'
import CreationPage from './create'
import DetailPage from './detail'
import UpdatePage from './update'

export default function Projects () {
  return (
    <Routes>
      <Route path='approval' element={PrivateRoute.generator(ApprovalPage)} />
      <Route path='create' element={PrivateRoute.generator(CreationPage)} />
      <Route path=':id/detail' element={PrivateRoute.generator(DetailPage)} />
      <Route path=':id/update' element={PrivateRoute.generator(UpdatePage)} />
      <Route path='*' element={<Error404 />} />
    </Routes>
  )
}
