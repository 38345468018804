import { Collapse } from 'antd'

export default function Hint4 () {
  return (
    <Collapse>
      <Collapse.Panel header='Skoring dari level 1.00 hingga 10.00 dengan gambaran:'>
        <table>
          <tbody>
            <tr>
              <th scope='row'>1</th>
              <td>Power transfer sangat kecil sekali</td>
            </tr>
            <tr>
              <th scope='row'>5</th>
              <td>
                Power transfer besar (contoh: {'>'}50 MW di luar
                Jawa) dan dibutuhkan segera
              </td>
            </tr>
            <tr>
              <th scope='row'>10</th>
              <td>
                Power transfer sangat besar (contoh: {'>'}300 MW di
                Jawa, {'>'}100 MW di luar Jawa) dan dibutuhkan
                segera
              </td>
            </tr>
          </tbody>
        </table>
      </Collapse.Panel>
    </Collapse>
  )
}
