import { useEffect } from 'react'

import Widget from './widget'

export * from './cards'
export * from './form'
export * from './layout'
export * from './tables'

// eslint-disable-next-line
const useMountEffect = func => useEffect(func, [])

export { useMountEffect, Widget }
