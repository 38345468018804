import { Navigate } from 'react-router-dom'

import { useAuth } from '../contexts'

export default function RootPage () {
  const { user } = useAuth()
  return user && user.id
    ? (
      <Navigate to='/dashboard' />
      )
    : (
      <Navigate to='/signin' />
      )
}
