import { useEffect, useRef } from 'react'
import { Button, Input } from 'antd'

import { useLocalization } from '../../localization'

export default function FilterText ({ setSelectedKeys, selectedKeys, confirm, clearFilters, visible }) {
  const filterRef = useRef()
  const { t } = useLocalization()

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        filterRef.current.focus()
      })
    }
  }, [visible])

  const handleSubmit = () => {
    confirm({ closeDropdown: true })
  }

  return (
    <div className='custom-filter-dropdown'>
      <Input
        ref={filterRef}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={handleSubmit}
      />
      <Button type='primary' onClick={handleSubmit}>{t('actions.search')}</Button>
      <Button onClick={clearFilters}>{t('actions.delete')}</Button>
    </div>
  )
}
