import { useLocation, Link } from 'react-router-dom'
import {
  AuditOutlined,
  DesktopOutlined,
  LogoutOutlined,
  PlusCircleOutlined
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'

import { useAuth } from '../../contexts'
import { useLocalization } from '../../localization'

const { Sider } = Layout

export default function Sidebar () {
  const { pathname } = useLocation()
  const { user, signOut: handleSignOut } = useAuth()
  const { t } = useLocalization()
  const selectedKeys = pathname
  const defaultOpenKeys = selectedKeys.split('/')[1]
  const isLoggedIn = Boolean(user?.id)
  return (
    <Sider breakpoint='md' collapsedWidth='0'>
      <div
        className='logo'
        style={{
          height: 64,
          margin: '20px 25px 10px',
          backgroundImage: 'url("/static/images/logo.svg")',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain'
        }}
      />
      <Menu
        theme='dark'
        mode='inline'
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
      >
        <Menu.Item key='/dashboard' icon={<DesktopOutlined />}>
          <Link to='/dashboard'>
            {t('app.dashboard')}
          </Link>
        </Menu.Item>
        <Menu.Item key='/projects/create' icon={<PlusCircleOutlined />}>
          <Link to='/projects/create'>
            {t('m.projects.create.short')}
          </Link>
        </Menu.Item>
        <Menu.Item key='/projects/approval' icon={<AuditOutlined />}>
          <Link to='/projects/approval'>
            {t('m.projects.approval')}
          </Link>
        </Menu.Item>
        {isLoggedIn && (
          <Menu.Item key='/signin' icon={<LogoutOutlined />}>
            <Link to='/signin' onClick={handleSignOut}>
              {t('m.users.logout')}
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  )
}
