import { useParams } from 'react-router-dom'
import { message, Col, Row, Skeleton } from 'antd'

import { withMainLayout, Widget } from '../../components'
import {
  ProjectCategoryForm,
  ProjectIdentifyForm,
  ProjectIRRCheckForm,
  ProjectMandatoryCheckForm,
  ProjectNonInfraParameterForm,
  ProjectParameterForm,
  ProjectParameterSelectionForm,
  ProjectSummaryForm
} from '../../components/modules'
import { useLocalization } from '../../localization'
import { useFetchProject } from '../../services'

const formMapper = [
  ProjectIdentifyForm,
  ProjectMandatoryCheckForm,
  ProjectIRRCheckForm,
  ProjectCategoryForm,
  ProjectParameterSelectionForm,
  ProjectParameterForm,
  ProjectNonInfraParameterForm
]

function UpdatePage () {
  const { id } = useParams()
  const { t } = useLocalization()
  const [project, loading, setProject] = useFetchProject(id, message.error)

  const { step } = project
  const FormComponent = formMapper[step] || ProjectIdentifyForm

  return (
    <div>
      <h2 className='title'>{t('m.projects.update')}</h2>
      <Row gutter={24}>
        {step < 100 && (
          <Col span={16}>
            <Widget>
              <Skeleton loading={loading} active>
                <FormComponent value={project} onChange={setProject} />
              </Skeleton>
            </Widget>
          </Col>
        )}
        <Col span={step === 100 ? 24 : 8}>
          <Widget title={t('m.projects.summary')}>
            <ProjectSummaryForm
              value={project}
              onChange={setProject}
              isUpdate
            />
          </Widget>
        </Col>
      </Row>
    </div>
  )
}

export default withMainLayout(UpdatePage, 'm.projects.update')
