import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'
import { UserOutlined, LoadingOutlined } from '@ant-design/icons'

import { version } from '../../../package.json'
import { useFormError } from '../../components'
import { useAuth } from '../../contexts'
import { useLocalization } from '../../localization'
import { postSession } from '../../services'

export default function SignIn () {
  const navigate = useNavigate()
  const { t } = useLocalization()
  const { user, signIn } = useAuth()
  const [name, setName] = useState()
  const [loading, setLoading] = useState()
  const [error, setError] = useFormError()

  const handleSubmit = async e => {
    e.preventDefault()
    if (loading) return
    try {
      setLoading(true)
      const response = await postSession({ name })
      signIn(response.data)
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (user?.id) {
      navigate('/')
    }
  }, [navigate, user])

  return (
    <div className='limiter ma-signin'>
      <link
        rel='stylesheet'
        type='text/css'
        href='/static/css/signin.util.css'
      />
      <link
        rel='stylesheet'
        type='text/css'
        href='/static/css/signin.main.css'
      />
      <div
        className='container-login100'
        style={{ backgroundImage: 'url("/static/images/bg-01.jpg")' }}
      >
        <div className='wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54'>
          <form
            className='login100-form validate-form'
            onSubmit={handleSubmit}
          >
            <span className='login100-form-title p-b-49'>
              {t('app.name')}
            </span>

            {error}

            <div className='wrap-input100 m-b-23'>
              <span className='label-input100'>
                {t('m.users.fields.name')}
              </span>
              <input
                className='input100'
                type='text'
                name='name'
                placeholder={t('m.users.hints.name')}
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <span className='focus-input100'>
                <UserOutlined />
              </span>
            </div>

            <div className='container-login100-form-btn'>
              <div className='wrap-login100-form-btn'>
                <div className='login100-form-bgbtn' />
                <button className='login100-form-btn'>
                  {loading && (
                    <Spin indicator={<LoadingOutlined />} />
                  )}
                  {t('m.users.login')}
                </button>
              </div>
            </div>
          </form>
          <div className='version'>
            <small>v {version}</small>
          </div>
        </div>
      </div>
    </div>
  )
}
