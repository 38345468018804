import Widget from '../widget'

export default function IconWithTextCard ({ backgroundColor = 'cyan', color = 'white', icon: IconComponent, title, subtitle }) {
  return (
    <Widget className='icon-with-text-card' style={{ backgroundColor, color }}>
      <div className='icon'>
        <IconComponent style={{ fontSize: 30 }} />
      </div>
      <div className='text'>
        <h1 className='title' style={{ color }}>{title}</h1>
        <p className='subtitle' style={{ color }}>{subtitle}</p>
      </div>
    </Widget>
  )
}
