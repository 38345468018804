import { Link } from 'react-router-dom'
import { Button } from 'antd'

import { useLocalization } from '../../../localization'

export default function ErrorPage (props) {
  const { t } = useLocalization()
  const { code = 404, message } = props

  document.title = `${t(message || `errors.${code}`)} | ${t('app.name')}`

  return (
    <div className='ma-page-error-container'>
      <div className='ma-page-error-content'>
        <div className='ma-error-code'>{code}</div>
        <h2 className='ma-text-center'>
          {t(message || `errors.${code}`)}
        </h2>
        <p className='ma-text-center'>
          <Link className='ma-btn ma-btn-primary' to='/dashboard'>
            <Button type='primary' size='large'>
              {t('actions.goHome')}
            </Button>
          </Link>
        </p>
      </div>
    </div>
  )
}
