import { Collapse } from 'antd'

export default function Hint6 () {
  return (
    <Collapse>
      <Collapse.Panel header='Skoring dari level 1.00 hingga 10.00 dengan gambaran:'>
        <table>
          <tbody>
            <tr>
              <th scope='row'>1</th>
              <td>
                Korektif dan maintenance CAPEX mempunyai impact <b>tidak signifikan</b> pada grid
              </td>
            </tr>
            <tr>
              <th scope='row'>5</th>
              <td>
                Korektif dan maintenance CAPEX mempunyai impact <b>cukup besar</b> pada grid
              </td>
            </tr>
            <tr>
              <th scope='row'>10</th>
              <td>
                Korektif dan maintenance CAPEX mempunyai impact <b>besar</b> pada grid dan harus segera
              </td>
            </tr>
          </tbody>
        </table>
      </Collapse.Panel>
    </Collapse>
  )
}
