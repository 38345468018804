import { useState } from 'react'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'

import { message, Button, Col, Row, Skeleton } from 'antd'

import { withMainLayout, Widget } from '../../components'
import { ProjectApprovalForm, ProjectDetail } from '../../components/modules'
import { useLocalization } from '../../localization'
import { useFetchProject } from '../../services'

function DetailPage () {
  const location = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()
  const { t } = useLocalization()
  const [project, loading] = useFetchProject(id, message.error)
  const [visibleApproval, setVisibleApproval] = useState(false)

  const prevUrl = location.state?.isApproval ? '/projects/approval' : '/dashboard'

  const handleApprovalClose = (event, param) => {
    if (param) navigate(prevUrl)
    else setVisibleApproval(false)
  }

  return (
    <div>
      <h2 className='title'>{t('m.projects.detail')}</h2>
      <Row gutter={24}>
        <Col span={24}>
          <Widget actions={[
            (
              <div key='actions' className='actions-btn text-left'>
                <Link to={prevUrl} className='ant-btn ant-btn-primary'>
                  {t('actions.goBack')}
                </Link>
                {!project.is_approved && (
                  <>
                    <Link to={`/projects/${id}/update`} className='ant-btn'>
                      {t('actions.update')}
                    </Link>
                    <Button onClick={() => setVisibleApproval(true)}>
                      {t('actions.approve')}
                    </Button>
                  </>
                )}
              </div>
            )
          ]}
          >
            <Skeleton loading={loading} active>
              <ProjectDetail value={project} />
            </Skeleton>
          </Widget>
        </Col>
      </Row>
      <ProjectApprovalForm id={project.id} visible={visibleApproval && !loading && !project.is_approved} onClose={handleApprovalClose} />
    </div>
  )
}

export default withMainLayout(DetailPage, 'm.projects.detail')
