import { useState, Fragment } from 'react'
import { Button, Divider, Form, InputNumber } from 'antd'

import { useLocalization } from '../../../../../localization'

import Hint0 from './hint0'
import Hint1 from './hint1'
import Hint2 from './hint2'
import Hint3 from './hint3'
import Hint4 from './hint4'
import Hint5 from './hint5'
import Hint6 from './hint6'

const hints = [
  <Hint0 key={0} />,
  <Hint1 key={1} />,
  <Hint2 key={2} />,
  <Hint3 key={3} />,
  <Hint4 key={4} />,
  <Hint5 key={5} />,
  <Hint6 key={6} />
]

export const importances = [10, 8, 9, 7.5, 8.5, 7.5, 7.5]

export default function ProjectParameterForm ({ value, onChange }) {
  const [form] = Form.useForm()
  const { t } = useLocalization()
  const [loading, setLoading] = useState(false)

  const handleBack = () => {
    const step = value.step - 1
    onChange(Object.assign({}, value, { step }))
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const newValue = await form.validateFields()
      newValue.score = value.params.reduce((v, i) => v + (newValue[`param_${i}`] * importances[i]), 0)
      onChange(
        Object.assign({ }, value, newValue, { step: 100, prevStep: 5 })
      )
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Form form={form} layout='horizontal' initialValues={value}>
      {hints.map((hint, i) => (
        value.params?.includes(i)
          ? (
            <Fragment key={i}>
              <Form.Item
                label={t(`m.projects.fields.category.params.${i}`)}
                name={`param_${i}`}
                rules={[
                  () => ({
                    validator (_, value) {
                      if (value >= 1 && value <= 10) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error(t('m.projects.formValidation.category.params.score')))
                    }
                  })
                ]}
              >
                <InputNumber min={1.00} max={10.00} />
              </Form.Item>
              {hint}
              <Divider />
            </Fragment>
            )
          : ''
      ))}
      <Form.Item>
        <Button onClick={handleBack} loading={loading} style={{ marginRight: 10 }}>
          {t('actions.prev')}
        </Button>
        <Button type='primary' onClick={handleSubmit} loading={loading}>
          {t('actions.next')}
        </Button>
      </Form.Item>
    </Form>
  )
}
