import { useState, Fragment } from 'react'
import { Button, Collapse, Divider, Form, InputNumber } from 'antd'

import { useLocalization } from '../../../../localization'

const params = [40, 41, 42, 43]
export const importances = [6, 6, 6, 2]

export default function ProjectNonInfraParameterForm ({ value, onChange }) {
  const [form] = Form.useForm()
  const { t } = useLocalization()
  const [loading, setLoading] = useState(false)

  const handleBack = () => {
    const step = 3
    onChange(Object.assign({}, value, { step }))
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const newValue = await form.validateFields()
      newValue.params = params
      newValue.score = params.reduce((v, i) => v + (newValue[`param_${i}`] * importances[i - 40]), 0)
      onChange(
        Object.assign({ }, value, newValue, { step: 100, prevStep: 6 })
      )
    } catch (error) {
      setLoading(false)
    }
  }

  const hint = (
    <Collapse>
      <Collapse.Panel header='Skoring dari level 1.00 hingga 10.00' />
    </Collapse>
  )

  return (
    <Form form={form} initialValues={value}>
      <Form.Item>
        {params.map(i => (
          <Fragment key={i}>
            <Form.Item
              label={t(`m.projects.fields.category.params.${i}`)}
              name={`param_${i}`}
              rules={[
                () => ({
                  validator (_, value) {
                    if (value >= 1 && value <= 10) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(t('m.projects.formValidation.category.params.score')))
                  }
                })
              ]}
            >
              <InputNumber min={1.00} max={10.00} />
            </Form.Item>
            {hint}
            <Divider />
          </Fragment>
        ))}
        <Button onClick={handleBack} loading={loading} style={{ marginRight: 10 }}>
          {t('actions.prev')}
        </Button>
        <Button type='primary' onClick={handleSubmit} loading={loading}>
          {t('actions.next')}
        </Button>
      </Form.Item>
    </Form>
  )
}
