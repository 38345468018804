import { withMainLayout, Widget } from '../../components'
import { Cards as ProjectCards, ProjectsTable } from '../../components/modules'
import { useLocalization } from '../../localization'

function Dashboard () {
  const { t } = useLocalization()
  return (
    <div>
      <ProjectCards />
      <h2 className='title' style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>{t('m.projects.list')}</h2>
      <Widget>
        <ProjectsTable />
      </Widget>
    </div>
  )
}

export default withMainLayout(Dashboard, 'app.dashboard')
