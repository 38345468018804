import { Collapse } from 'antd'

export default function Hint2 () {
  return (
    <Collapse>
      <Collapse.Panel header='Skoring dari level 1.00 hingga 10.00 dengan gambaran:'>
        <table>
          <tbody>
            <tr>
              <th scope='row'>1</th>
              <td>BPP Pembangkitan tidak turun signifikan (dihitung dalam satu grid) </td>
            </tr>
            <tr>
              <th scope='row'>5</th>
              <td>BPP Pembangkitan turun 1% (dihitung dalam satu grid)</td>
            </tr>
            <tr>
              <th scope='row'>10</th>
              <td>BPP Pembangkitan turun {'>'}2% (dihitung dalam satu grid)</td>
            </tr>
          </tbody>
        </table>
      </Collapse.Panel>
    </Collapse>
  )
}
