import { useState } from 'react'
import { Form, Input, Modal } from 'antd'

import { useFormError } from '../../../../components'
import { useAuth } from '../../../../contexts'
import { useLocalization } from '../../../../localization'
import { putProjectApproval } from '../../../../services'

export default function ProjectApprovalForm ({ id, onClose, visible = false }) {
  const [form] = Form.useForm()
  const { user } = useAuth()
  const { t } = useLocalization()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useFormError()

  const handleSubmit = async event => {
    setLoading(true)
    try {
      const newValue = await form.validateFields()
      const response = await putProjectApproval(Object.assign({ id, approver_id: user.id }, newValue))
      onClose(event, response.data)
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }

  return (
    <Modal
      visible={visible}
      onOk={handleSubmit}
      onCancel={onClose}
      confirmLoading={loading}
      bodyStyle={{ paddingBottom: 0 }}
    >
      <Form form={form} layout='vertical'>
        {error}
        <Form.Item
          label={t('m.projects.fields.approvalNotes')}
          name='approval_notes'
          rules={[
            {
              required: true,
              message: t('errors.formValidation.required')
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}
