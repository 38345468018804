import { Collapse } from 'antd'

export default function Hint0 () {
  return (
    <Collapse>
      <Collapse.Panel header='Skoring dari level 1.00 hingga 10.00 dengan gambaran:'>
        <table>
          <tbody>
            <tr>
              <th scope='row' style={{ width: 25 }}>1</th>
              <td>
                Peningkatan kWh diperoleh sangat kecil sekali
              </td>
            </tr>
            <tr>
              <th scope='row'>3</th>
              <td>
                Peningkatan kWh diperoleh dalam waktu 1 tahun,
                dengan rasio benefit (jumlah peningkatan
                penjualan setahun dalam Rp) terhadap cost
                (investasi dalam rupiah) <b>lebih kecil</b> dari growth
                rate RUPTL di wilayah dimana project berada
              </td>
            </tr>
            <tr>
              <th scope='row'>5</th>
              <td>
                Peningkatan kWh diperoleh dalam waktu 1 tahun,
                dengan rasio benefit (jumlah peningkatan
                penjualan setahun dalam Rp) terhadap cost
                (investasi dalam rupiah) <b>sama dengan</b> growth rate
                RUPTL di wilayah dimana project berada
              </td>
            </tr>
            <tr>
              <th scope='row'>10</th>
              <td>
                Peningkatan kWh diperoleh dalam waktu 1 tahun,
                dengan rasio benefit (jumlah peningkatan
                penjualan setahun dalam Rp) terhadap cost
                (investasi dalam rupiah) <b>lebih besar</b> dari growth
                rate RUPTL di wilayah dimana project berada
              </td>
            </tr>
          </tbody>
        </table>
      </Collapse.Panel>
    </Collapse>
  )
}
