import { Collapse } from 'antd'

export default function Hint1 () {
  return (
    <Collapse>
      <Collapse.Panel header='Skoring dari level 1.00 hingga 10.00 dengan gambaran:'>
        <table>
          <tbody>
            <tr>
              <th scope='row'>1</th>
              <td>Bukan daerah strategis
              </td>
            </tr>
            <tr>
              <th scope='row'>5</th>
              <td>Daerah semi-strategis (contoh: Kereta Cepat, MRT)</td>
            </tr>
            <tr>
              <th scope='row'>10</th>
              <td>Daerah strategis (contoh: Istana Negera, Gedung DPR) </td>
            </tr>
          </tbody>
        </table>
      </Collapse.Panel>
    </Collapse>
  )
}
