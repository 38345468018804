import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form } from 'antd'

import { useFormError } from '../../../../components'
import { useAuth } from '../../../../contexts'
import { useLocalization } from '../../../../localization'
import { postProject, putProject } from '../../../../services'

export default function ProjectSummaryForm ({ value, onChange, isUpdate = false }) {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { user } = useAuth()
  const { intl, t } = useLocalization()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useFormError()

  const handleBack = () => {
    const step = value.prevStep
    onChange(Object.assign({}, value, { step }))
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const callService = isUpdate ? putProject : postProject
      const meta = isUpdate ? { updater_id: user.id } : { creator_id: user.id }
      if (!value.is_profitable) {
        delete value.category
        for (let i = 0; i < 7; i++) {
          value[`param_${i}`] = 0
        }
        for (let i = 0; i < 4; i++) {
          value[`param_4${i}`] = 0
        }
        value.score = 0
      }
      const response = await callService(Object.assign(meta, value))
      navigate(`/projects/${response.data.id}/detail`)
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }

  return (
    <Form form={form} layout='vertical'>
      {error}
      <dl>
        <dt>{t('m.projects.fields.name')}</dt>
        <dd>{value.name}</dd>
        <dt>{t('m.projects.fields.unit')}</dt>
        <dd>{value.unit}</dd>
        <dt>{t('m.projects.fields.startingDate')}</dt>
        <dd>{intl.formatDate(value.starting_date)}</dd>
        {typeof value.is_mandatory !== 'undefined' && (
          <>
            <dt>{t('m.projects.fields.mandatory')}</dt>
            <dd>
              {t(`choices.${value.is_mandatory ? 'yes' : 'no'}`)}
            </dd>
          </>
        )}
        {!value.is_mandatory && typeof value.is_profitable !== 'undefined' && (
          <>
            <dt>{t('m.projects.fields.profitable')}</dt>
            <dd>
              {t(`choices.${value.is_profitable ? 'yes' : 'no'}`)}
            </dd>
          </>
        )}
        {value.is_profitable && typeof value.category !== 'undefined' && value.category !== null && (
          <>
            <dt>{t('m.projects.fields.category')}</dt>
            <dd>
              {t(`m.projects.fields.category.${value.category}`)}
            </dd>
          </>
        )}
        {value.is_profitable && value.params?.length > 0 && (
          <>
            <dt>{t('m.projects.fields.category.params')}</dt>
            <dd>
              <ul>
                {value.params.map(i => (
                  <li key={i}>
                    {t(`m.projects.fields.category.params.${i}`)}{' '}
                    ({value[`param_${i}`] || 0})
                  </li>
                ))}
              </ul>
            </dd>
          </>
        )}
        {value.is_profitable && value.score > 0 && (
          <>
            <dt>{t('m.projects.fields.score')}</dt>
            <dd>{value.score}</dd>
          </>
        )}
      </dl>
      {value.step === 100 && (
        <Form.Item>
          <Button
            onClick={handleBack}
            loading={loading}
            style={{ marginRight: 10 }}
          >
            {t('actions.prev')}
          </Button>
          <Button
            type='primary'
            onClick={handleSubmit}
            loading={loading}
          >
            {t('actions.save')}
          </Button>
        </Form.Item>
      )}
    </Form>
  )
}
