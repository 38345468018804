import { useState } from 'react'
import { Alert } from 'antd'

import { normalizeErrors } from '../services'

export function useFormError () {
  const [rawError, setRawError] = useState()

  const error = rawError && (
    <Alert
      message='Error'
      description={
        Array.isArray(rawError)
          ? rawError.map((item, i) => <p key={i}>{item}</p>)
          : rawError
      }
      type='error'
      className='m-b-23'
      showIcon
    />
  )

  const setError = error => setRawError(normalizeErrors(error.message))

  return [error, setError]
}
