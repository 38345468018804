import { useState } from 'react'
import { Button, Checkbox, Col, Divider, Form, Input, Row } from 'antd'

import { useLocalization } from '../../../../localization'

export default function ProjectMandatoryCheckForm ({ value, onChange }) {
  const [form] = Form.useForm()
  const { t } = useLocalization()
  const [loading, setLoading] = useState(false)

  const handleBack = () => {
    const step = value.step - 1
    onChange(Object.assign({}, value, { step }))
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const newValue = await form.validateFields()
      const mandatory = newValue.mandatory_params?.length > 0
      const step = mandatory > 0 ? 100 : 2
      if (mandatory) {
        value.is_profitable = false
        value.financial_irr = 0
        delete value.category
        delete value.params
        for (let i = 0; i < 7; i++) value[`param_${i}`] = 0
        value.score = 0
      }
      onChange(Object.assign({ }, value, newValue, { is_mandatory: mandatory, step, prevStep: 1 }))
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Form form={form} layout='vertical' initialValues={value}>
      <Form.Item label={t('m.projects.hints.mandatory.params')} name='mandatory_params'>
        <Checkbox.Group>
          <Row>
            {[0, 1, 2, 3, 4].map(i => (
              <Col key={i} span={24}>
                <Checkbox value={i}>{t(`m.projects.fields.mandatory.params.${i}`)}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        label={t('m.projects.fields.mandatory.justification')} name='mandatory_justification' rules={[
          {
            required: true,
            message: t('errors.formValidation.required')
          }
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Divider />
      <Form.Item>
        <Button onClick={handleBack} loading={loading} style={{ marginRight: 10 }}>
          {t('actions.prev')}
        </Button>
        <Button type='primary' onClick={handleSubmit} loading={loading}>
          {t('actions.next')}
        </Button>
      </Form.Item>
    </Form>
  )
}
