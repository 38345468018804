import { useState } from 'react'
import { Button, Checkbox, Col, Divider, Form, Row } from 'antd'

import { useLocalization } from '../../../../localization'

const paramAvailability = [
  [1, 0, 0, 1],
  [1, 0, 0, 0],
  [1, 0, 0, 0],
  [1, 1, 1, 1],
  [1, 0, 0, 1],
  [1, 1, 1, 1],
  [0, 1, 1, 0]
]

export default function ProjectParameterSelectionForm ({ value, onChange }) {
  const [form] = Form.useForm()
  const { t } = useLocalization()
  const [loading, setLoading] = useState(false)

  const handleBack = () => {
    const step = value.step - 1
    onChange(Object.assign({}, value, { step }))
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const newValue = await form.validateFields()
      newValue.params?.sort()
      const additionalValue = { step: 5, prevStep: 4, score: 0 }
      for (let i = 0; i < 7; i++) {
        if (!newValue.params?.includes(i)) {
          additionalValue[`param_${i}`] = 0
        }
      }
      onChange(
        Object.assign({ }, value, newValue, additionalValue)
      )
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Form form={form} layout='vertical' initialValues={value}>
      <Form.Item
        label={t('m.projects.hints.category.params')}
        name='params'
        rules={[
          () => ({
            validator (_, value) {
              if (value?.length === 3) {
                return Promise.resolve()
              }
              return Promise.reject(new Error(t('m.projects.formValidation.category.params')))
            }
          })
        ]}
      >
        <Checkbox.Group>
          <Row>
            {paramAvailability.map((item, i) => (
              <Col key={i} span={24}>
                <Checkbox value={i} disabled={item[value.category] === 0}>{t(`m.projects.fields.category.params.${i}`)}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Divider />
      <Form.Item>
        <Button onClick={handleBack} loading={loading} style={{ marginRight: 10 }}>
          {t('actions.prev')}
        </Button>
        <Button type='primary' onClick={handleSubmit} loading={loading}>
          {t('actions.next')}
        </Button>
      </Form.Item>
    </Form>
  )
}
