import { useState } from 'react'

import { useLocalization } from '../../localization'

export default function usePagination () {
  const { intl } = useLocalization()
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageSizeOptions: ['10', '25', '50', '100'],
    showSizeChanger: true,
    showTotal: (total, range) => intl.formatMessage({ id: 'table.pagination.total' }, { start: range[0], end: range[1], total })
  })

  return [pagination, setPagination]
}
