import { ProvideAuth } from './contexts'
import Localization from './localization'
import Pages from './pages'

import './styles/all.less'

export default function App () {
  return (
    <Localization>
      <ProvideAuth>
        <Pages />
      </ProvideAuth>
    </Localization>
  )
}
