import { ConfigProvider } from 'antd'
import { useIntl, IntlProvider } from 'react-intl'

import enUS from './en-US'
import idID from './id-ID'

const locales = {
  'en-US': enUS,
  'id-ID': idID
}

export function useLocalization () {
  const intl = useIntl()

  intl.formatTimeStamp = value => intl.formatDate(value, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })

  intl.formatRupiah = value => intl.formatNumber(value, { style: 'currency', currency: 'IDR', maximumFractionDigits: 0 })
  intl.formatSimpleDecimal = value => intl.formatNumber(value, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

  const t = string => (intl ? intl.formatMessage({ id: string }) : string)

  return { intl, t }
}

export default function Localization ({ children, locale = 'id-ID' }) {
  const currentLocale = locales[locale] || locales['id-ID']
  return (
    <ConfigProvider locale={currentLocale.localeAntd}>
      <IntlProvider
        locale={currentLocale.locale}
        messages={currentLocale.messages}
      >
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}
