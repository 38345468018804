const fetchWithTimeOut = (url, options, timeout = 30000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((resolve, reject) =>
      setTimeout(() => reject(new Error(JSON.stringify({ l10n: 'res.errors.timeout' }))), timeout)
    )
  ])
}

const apiUrl = process.env.NODE_ENV === 'production' ? '/api' : 'http://reviewer.localhost/api'
const storageUrl = process.env.NODE_ENV === 'production' ? '/storage' : 'http://reviewer.localhost/storage'

export const request = async options => {
  const headers = new Headers({
    'Content-Type': 'application/json'
  })
  const defaults = { headers }
  options = Object.assign({}, defaults, options)
  if (!options.method && options.body) {
    options.method = 'POST'
  }
  if (options.body) options.body = JSON.stringify(options.body)
  const response = await fetchWithTimeOut(`${apiUrl}/${options.url}`, options)
  const json = await response.json()
  if (!json.success) throw new Error(JSON.stringify(json.errors))
  return json
}

export const serverUrl = url => `${apiUrl}/${url}`
export const serverStorageUrl = url => `${storageUrl}/${url}`

export const cleanParams = params => {
  const retval = { ...params }
  Object.keys(retval).forEach(key => retval[key] === undefined || retval[key] === null ? delete retval[key] : {})
  return retval
}
