import localeAntd from 'antd/es/locale/id_ID'

const messages = {
  'app.name': 'Evaluasi Usulan Program Investasi PT PLN (Persero)',
  'app.fullname': 'Evaluasi Usulan Program Investasi PT PLN (Persero)',
  'app.owner': 'PT PLN (Persero)',
  'app.dashboard': 'Dashboard',
  'actions.all': 'Pilih Semua',
  'actions.approve': 'Setujui',
  'actions.cancel': 'Batal',
  'actions.close': 'Tutup',
  'actions.delete': 'Hapus',
  'actions.delete.confirm': 'Apakah Anda yakin?',
  'actions.detail': 'Detail',
  'actions.download': 'Download',
  'actions.downloadSample': 'Download Contoh',
  'actions.duplicate': 'Buat Salinan',
  'actions.export': 'Ekspor',
  'actions.export.doc': 'Download DOC',
  'actions.export.pdf': 'Download PDF',
  'actions.export.xls': 'Download XLS',
  'actions.fillOut': 'Lengkapi',
  'actions.goBack': 'Kembali',
  'actions.goHome': 'Kembali ke Beranda',
  'actions.next': 'Berikutnya',
  'actions.ok': 'OK',
  'actions.pickOne': '-- Pilih --',
  'actions.prev': 'Sebelumnya',
  'actions.preview': 'Pratinjau',
  'actions.reset': 'Reset',
  'actions.save': 'Simpan',
  'actions.search': 'Cari',
  'actions.send': 'Kirim',
  'actions.start': 'Mulai',
  'actions.stop': 'Selesai',
  'actions.update': 'Ubah',
  'actions.upload': 'Upload',
  'actions.validate': 'Validasi',
  'choices.yes': 'Iya',
  'choices.no': 'Tidak',
  'errors.tryAgain': 'Coba kembali',
  'errors.formValidation.invalidEmail': 'Alamat email tidak valid',
  'errors.formValidation.uploading': 'Silahkan tunggu sampai semua file selesai diupload',
  'errors.formValidation.required': 'Input harus dilengkapi',
  'errors.timeout':
        'Terjadi kesalahan ketika melakukan request. Periksa koneksi jaringan Anda, dan coba beberapa saat lagi.',
  'errors.404': 'Halaman tidak ditemukan',
  'hints.file': 'Klik atau geser berkas ke area ini untuk mengunggah',
  'hints.file.multiple': 'Anda dapat mengunggah satu atau lebih berkas sekaligus',
  'm.projects': 'Proyek',
  'm.projects.approval': 'Persetujuan Review',
  'm.projects.approval.create': 'Tambah Persetujuan',
  'm.projects.cards.total': 'Usulan',
  'm.projects.cards.mandatory': 'Mandatory',
  'm.projects.cards.profitable': 'Profitable',
  'm.projects.cards.minScore200': 'Score > 200',
  'm.projects.cards.approved': 'Disetujui',
  'm.projects.create': 'Tambah Usulan Proyek',
  'm.projects.create.short': 'Tambah Usulan',
  'm.projects.detail': 'Detail Proyek',
  'm.projects.fields.approvalNotes': 'Catatan',
  'm.projects.fields.approvedAt': 'Disetujui pada',
  'm.projects.fields.approver.name': 'Disetujui oleh',
  'm.projects.fields.category': 'Kategori',
  'm.projects.fields.category.params': 'Parameter penilaian',
  'm.projects.fields.category.params.importance': 'Importance',
  'm.projects.fields.category.params.score': 'Nilai',
  'm.projects.fields.category.params.scoreImportance': 'Nilai x Importance',
  'm.projects.fields.category.params.short': 'Parameter',
  'm.projects.fields.category.params.0': 'Peningkatan kWh jual',
  'm.projects.fields.category.params.1': 'Minimalisasi padam',
  'm.projects.fields.category.params.2': 'Penurunan BPP',
  'm.projects.fields.category.params.3': 'Peningkatan keandalan',
  'm.projects.fields.category.params.4': 'Evakuasi daya',
  'm.projects.fields.category.params.5': 'Efisiensi atau pengurangan losses',
  'm.projects.fields.category.params.6': 'Urgency/impact',
  'm.projects.fields.category.params.40': 'Kesesuaian dengan arah dan prioritas RJP',
  'm.projects.fields.category.params.41': 'Tingkat kemendesakan',
  'm.projects.fields.category.params.42': 'Dampak program (kemanfaatan)',
  'm.projects.fields.category.params.43': 'Kualitas proposal (terutama terkait asumsi finansial)',
  'm.projects.fields.category.0': 'Pengembangan Sistem',
  'm.projects.fields.category.1': 'Korektif',
  'm.projects.fields.category.2': 'Maintenance CAPEX',
  'm.projects.fields.category.3': 'Distribusi',
  'm.projects.fields.category.4': 'Non-Infrastruktur',
  'm.projects.fields.description': 'Deskripsi usulan',
  'm.projects.fields.documents': 'Lampiran dokumen',
  'm.projects.fields.irr': 'IRR',
  'm.projects.fields.irr.financial': 'Financial IRR',
  'm.projects.fields.irr.economic': 'Economic IRR',
  'm.projects.fields.mandatory': 'Apakah usulan bersifat mandatory?',
  'm.projects.fields.mandatory.short': 'Mandatory',
  'm.projects.fields.mandatory.params': 'Kriteria yang dipenuhi',
  'm.projects.fields.mandatory.params.0': 'Legal risk: High (sesudah dimitigasi)',
  'm.projects.fields.mandatory.params.1': 'Penugasan pemerintah',
  'm.projects.fields.mandatory.params.2': 'Pendanaan dari PNM atau didukung pemerintah',
  'm.projects.fields.mandatory.params.3': 'Adanya klausul Take or Pay',
  'm.projects.fields.mandatory.params.4': 'Merupakan pembangkit EBT prioritas (PLTA dan PLTP)',
  'm.projects.fields.mandatory.justification': 'Justifikasi',
  'm.projects.fields.name': 'Nama usulan proyek',
  'm.projects.fields.name.short': 'Nama',
  'm.projects.fields.profitable': 'Apakah usulan bersifat profitable?',
  'm.projects.fields.profitable.short': 'Profitable',
  'm.projects.fields.unit': 'Unit pengusul',
  'm.projects.fields.unit.short': 'Unit',
  'm.projects.fields.review.approved': 'Disetujui',
  'm.projects.fields.review.score': 'Review',
  'm.projects.fields.review.nonPriority': 'Bukan Prioritas',
  'm.projects.fields.score': 'Nilai akhir',
  'm.projects.fields.startingDate': 'Tanggal',
  'm.projects.fields.valuation': 'Nilai',
  'm.projects.fields.wacc': 'WACC',
  'm.projects.formValidation.category.params': 'Anda harus memilih tepat sebanyak tiga parameter',
  'm.projects.formValidation.category.params.score': 'Nilai parameter harus berada pada rentang 1.00 hingga 10.00',
  'm.projects.hints.mandatory.params': 'Apakah usulan proyek memenuhi kriteria berikut?',
  'm.projects.hints.category.params': 'Silahkan pilih tiga parameter yang akan diberikan penilaian',
  'm.projects.list': 'Daftar Proyek',
  'm.projects.update': 'Ubah Proyek',
  'm.projects.summary': 'Rangkuman Proyek',
  'm.users': 'User',
  'm.users.login': 'Masuk',
  'm.users.loginAs': 'Anda masuk sebagai',
  'm.users.logout': 'Keluar',
  'm.users.setting': 'Pengaturan',
  'm.users.fields.name': 'Nama',
  'm.users.fields.username': 'Username',
  'm.users.hints.name': 'Masukkan nama Anda',
  'menu.group': 'Group',
  'menu.item': 'Item',
  'table.fields._i': 'No',
  'table.fields._actions': 'Aksi',
  'table.fields.createdAt': 'Dibuat pada',
  'table.fields.creator.name': 'Dibuat oleh',
  'table.fields.updatedAt': 'Diperbarui pada',
  'table.fields.updater.name': 'Diperbarui oleh',
  'table.pagination.total':
        'Menampilkan {start, number} sampai {end, number} dari {total, number} total data',
  'success.ok': 'OK'
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  locale: 'id-ID',
  localeAntd,
  messages
}
