import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { useAuth } from '../contexts'

import Dashboard from './dashboard'
import Error404 from './error/404'
import PrivateRoute from './privateRoute'
import Projects from './projects'
import RootPage from './root'
import SignIn from './signin'

export default function Pages () {
  const intl = useIntl()
  const auth = useAuth()

  useEffect(() => {
    if (!auth.user?.id) {
      auth.reload()
    }
  }, [intl, auth])

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<RootPage />} />
        <Route path='dashboard' element={PrivateRoute.generator(Dashboard)} />
        <Route path='projects/*' element={<Projects />} />
        <Route path='signin' element={<SignIn />} />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  )
}
