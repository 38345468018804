import { Col, Row, Table } from 'antd'

import { useLocalization } from '../../../localization'
import { ApiUtils } from '../../../utils'

import { importances as paImportances } from './forms/parameters'
import { importances as niImportances } from './forms/nonInfraParameter'

export default function ProjectDetail ({ value }) {
  const { intl, t } = useLocalization()

  return (
    <Row gutter={24}>
      <Col sm={24} md={16}>
        <dl>
          <dt>{t('m.projects.fields.name')}</dt>
          <dd>{value.name}</dd>
          <dt>{t('m.projects.fields.unit')}</dt>
          <dd>{value.unit}</dd>
          <dt>{t('m.projects.fields.startingDate')}</dt>
          <dd>{intl.formatDate(value.starting_date)}</dd>
          <dt>{t('m.projects.fields.valuation')}</dt>
          <dd>{intl.formatRupiah(value.valuation)}</dd>
          <dt>{t('m.projects.fields.description')}</dt>
          <dd>{value.description}</dd>
          {value.documents?.length > 0 && (
            <>
              <dt>{t('m.projects.fields.documents')}</dt>
              <dd>
                <ul style={{ paddingLeft: 13 }}>
                  {value.documents.map(doc => (
                    <li key={doc.name}>
                      <a href={ApiUtils.serverStorageUrl(doc.file_path)} target='_blank' rel='noreferrer'>{doc.name}</a>
                    </li>
                  ))}
                </ul>
              </dd>
            </>
          )}
          <dt>{t('m.projects.fields.mandatory')}</dt>
          <dd>
            {t(`choices.${value.is_mandatory ? 'yes' : 'no'}`)}
          </dd>
          {value.is_mandatory && (
            <>
              <dt>{t('m.projects.fields.mandatory.params')}</dt>
              <dd>
                <ul style={{ paddingLeft: 13 }}>
                  {[0, 1, 2, 3, 4].filter(i => value[`mandatory_${i}`]).map(i => (
                    <li key={i}>{t(`m.projects.fields.mandatory.params.${i}`)}</li>
                  ))}
                </ul>
              </dd>
            </>
          )}
          <dt>{t('m.projects.fields.mandatory.justification')}</dt>
          <dd>
            {value.mandatory_justification}
          </dd>
          {!value.is_mandatory && (
            <>
              <dt>{t('m.projects.fields.profitable')}</dt>
              <dd>
                {t(`choices.${value.is_profitable ? 'yes' : 'no'}`)}
              </dd>
              <dt>{t('m.projects.fields.irr')}</dt>
              <dd>{value.financial_irr}%</dd>
              <dt>{t('m.projects.fields.wacc')}</dt>
              <dd>{value.wacc}%</dd>
            </>
          )}
          {value.is_profitable && value.score > 0 && (
            <>
              <dt>{t('m.projects.fields.category')}</dt>
              <dd>
                {t(`m.projects.fields.category.${value.category}`)}
              </dd>
            </>
          )}
          {value.is_profitable && value.score > 0 && value.category !== null && value.category !== 5 && (
            <>
              <dt>{t('m.projects.fields.category.params')}</dt>
              <dd>
                <Table
                  size='small'
                  pagination={false}
                  bordered
                  columns={[
                    { title: t('m.projects.fields.category.params.short'), dataIndex: 'title' },
                    { title: t('m.projects.fields.category.params.score'), dataIndex: 'score', align: 'right' },
                    { title: t('m.projects.fields.category.params.importance'), dataIndex: 'importance', align: 'right' },
                    { title: t('m.projects.fields.category.params.scoreImportance'), dataIndex: 'scoreImportance', align: 'right' }
                  ]}
                  dataSource={[0, 1, 2, 3, 4, 5, 6, 40, 41, 42, 43].filter(i => value[`param_${i}`]).map(i => {
                    const importances = i >= 40 ? niImportances : paImportances
                    const j = i >= 40 ? i - 40 : i
                    return {
                      key: i,
                      title: t(`m.projects.fields.category.params.${i}`),
                      score: intl.formatSimpleDecimal(value[`param_${i}`]),
                      importance: intl.formatSimpleDecimal(importances[j]),
                      scoreImportance: intl.formatSimpleDecimal(value[`param_${i}`] * importances[j])
                    }
                  })}
                  summary={() => (
                    <Table.Summary>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={3}>{t('m.projects.fields.score')}</Table.Summary.Cell>
                        <Table.Summary.Cell align='right'>{intl.formatSimpleDecimal(value.score)}</Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                />
              </dd>
            </>
          )}
        </dl>
      </Col>
      <Col sm={24} md={8}>
        <dl>
          <dt>{t('table.fields.creator.name')}</dt>
          <dd>{value.creator?.name}</dd>
          <dt>{t('table.fields.createdAt')}</dt>
          <dd>{intl.formatTimeStamp(value.created_at)}</dd>
          {value.updater && (
            <>
              <dt>{t('table.fields.updater.name')}</dt>
              <dd>{value.updater?.name}</dd>
              <dt>{t('table.fields.updatedAt')}</dt>
              <dd>{intl.formatTimeStamp(value.updated_at)}</dd>
            </>
          )}
          {value.approver && (
            <>
              <dt>{t('m.projects.fields.approver.name')}</dt>
              <dd>{value.approver?.name}</dd>
              <dt>{t('m.projects.fields.approvedAt')}</dt>
              <dd>{intl.formatTimeStamp(value.approved_at)}</dd>
            </>
          )}
        </dl>
      </Col>
    </Row>
  )
}
