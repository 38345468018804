import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Tooltip } from 'antd'
import {
  DeploymentUnitOutlined,
  DollarOutlined,
  FileProtectOutlined,
  LikeOutlined,
  PlusCircleOutlined,
  StarOutlined
} from '@ant-design/icons'

import { useLocalization } from '../../../../localization'
import { getProjectStatistic } from '../../../../services'
import { useMountEffect, IconWithTextCard, Widget } from '../../../'

export default function Cards () {
  const [stats, setStats] = useState({})
  const { t } = useLocalization()

  const fetchStats = async () => {
    try {
      const response = await getProjectStatistic()
      setStats(response.data)
    } catch (error) { }
  }

  useMountEffect(() => {
    fetchStats()
  })

  const minScore200 = stats.scores ? stats.scores['200_250'] + stats.scores['250_300'] : 0

  return (
    <Row gutter={16}>
      <Col lg={4} md={6} sm={12}>
        <IconWithTextCard backgroundColor='green' icon={DeploymentUnitOutlined} title={stats.all} subtitle={t('m.projects.cards.total')} />
      </Col>
      <Col lg={4} md={6} sm={12}>
        <IconWithTextCard backgroundColor='orange' icon={StarOutlined} title={stats.mandatory} subtitle={t('m.projects.cards.mandatory')} />
      </Col>
      <Col lg={4} md={6} sm={12}>
        <IconWithTextCard backgroundColor='teal' icon={DollarOutlined} title={stats.profitable} subtitle={t('m.projects.cards.profitable')} />
      </Col>
      <Col lg={4} md={6} sm={12}>
        <IconWithTextCard backgroundColor='red' icon={LikeOutlined} title={minScore200} subtitle={t('m.projects.cards.minScore200')} />
      </Col>
      <Col lg={4} md={6} sm={12}>
        <IconWithTextCard backgroundColor='olive' icon={FileProtectOutlined} title={stats.approved} subtitle={t('m.projects.cards.approved')} />
      </Col>
      <Col lg={2} md={3} sm={12}>
        <Widget style={{ backgroundColor: '#1890ff', padding: '8px 0' }}>
          <Link to='/projects/create'>
            <Tooltip title={t('m.projects.create')}>
              <PlusCircleOutlined style={{ color: 'white', fontSize: 30 }} />
            </Tooltip>
          </Link>
        </Widget>
      </Col>
    </Row>
  )
}
