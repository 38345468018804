import { useState } from 'react'
import { Button, Divider, Form, InputNumber } from 'antd'

import { useLocalization } from '../../../../localization'

export default function ProjectIRRCheckForm ({ value, onChange }) {
  const [form] = Form.useForm()
  const { t } = useLocalization()
  const [loading, setLoading] = useState(false)

  const handleBack = () => {
    const step = value.step - 1
    onChange(Object.assign({}, value, { step }))
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const newValue = await form.validateFields()
      const profitable = newValue.financial_irr > newValue.wacc
      const step = profitable ? 3 : 100
      onChange(
        Object.assign({ }, value, newValue, { is_profitable: profitable, step, prevStep: 2 })
      )
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Form form={form} layout='horizontal' initialValues={value}>
      <Form.Item
        label={t('m.projects.fields.irr')}
        name='financial_irr'
        rules={[
          {
            required: true,
            message: t('errors.formValidation.required')
          }
        ]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label={t('m.projects.fields.wacc')}
        name='wacc'
        rules={[
          {
            required: true,
            message: t('errors.formValidation.required')
          }
        ]}
      >
        <InputNumber />
      </Form.Item>
      <Divider />
      <Form.Item>
        <Button onClick={handleBack} loading={loading} style={{ marginRight: 10 }}>
          {t('actions.prev')}
        </Button>
        <Button type='primary' onClick={handleSubmit} loading={loading}>
          {t('actions.next')}
        </Button>
      </Form.Item>
    </Form>
  )
}
