import { Navigate } from 'react-router-dom'

import { useAuth } from '../contexts'

function PrivateRoute ({ children }) {
  const { user } = useAuth()
  return user ? children : <Navigate to='/signin' />
}

PrivateRoute.generator = TheComponent => (
  <PrivateRoute>
    <TheComponent />
  </PrivateRoute>
)

export default PrivateRoute
